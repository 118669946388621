import React from 'react';


import MainLayout from '@hoc/layout';
import Seo from '@components/seo/seo';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import AboutSection from '@components/about-section/about-section';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';


import * as s from './o-nas.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';


const ONas = () => {



    return (
        <MainLayout>

            <Seo
                title='O naszej firmie'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
            />

			<BreadCrumbs />

            <section className={`${s.hero} ${s_wrapper.apply}`}>
                <h1>O naszej firmie</h1>

            </section>

            <AboutSection />

            <ContactSection />

            <MapSection />

        </MainLayout>
    )
};

export default ONas;
